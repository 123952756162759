const tr = {
  settings: 'Tweet Ayarları',
  name: 'Ad soyad',
  username: 'Kullanıcı adı'
};

const en = {
  settings: 'Tweet Settings',
  name: 'Name and Surname',
  username: 'Username'
};

const language = {
  tr,
  en
};

export { language };
